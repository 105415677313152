import { UNSUBSCRIBE, WORKSPACE } from "gatsby-env-variables"

const axios = require("axios").default

const getWorkspace = ({ workspace_id }) => {
  const request = axios.get(
    `${WORKSPACE.url}/${workspace_id}/unsubscribe-params`,
    {
      headers: { "x-api-key": WORKSPACE.x_api_key },
    }
  )

  return request
}

const requestUnsubscribe = ({ customer_id, workspace_id }) => {
  const request = axios.post(
    `${UNSUBSCRIBE.url}/${customer_id}/${workspace_id}`,
    {},
    {
      headers: { "x-api-key": UNSUBSCRIBE.x_api_key },
    }
  )

  return request
}

export { getWorkspace, requestUnsubscribe }
