import React from "react"

const Error = (props) => {
  return (
    <>
      <h1 className="title">ops, algo deu errado :(</h1>
      <p className="description">
        Infelizmente encontramos um problema para te tirar da nossa lista de
        emails agora. Tente novamente mais tarde pelo link de desinscrição do
        último email que te enviamos.
      </p>
    </>
  )
}

export default Error
