import React from "react"

const TimeOut = (props) => {
  return (
    <>
      <h1 className="title">opa, você já está desinscrito!</h1>
      <p className="description">
        Você já optou por se desinscrever de nossos e-mails!
      </p>
    </>
  )
}

export default TimeOut
