import React from "react"

import Unsubscribe from "layout/Unsubscribe"

const Home = (props) => {
  return (
    <Unsubscribe />
  )
}

export default Home
