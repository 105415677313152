import React from "react"

import IconConfirmacao from "../../../static/icons/outlined/Confirmacao.svg"

const Maintain = (props) => {
  return (
    <>
      <figure>
        <img src={IconConfirmacao} alt="Confirmado!" />
      </figure>
      <h1 className="title">ok, continuaremos!</h1>
      <p className="description">
        Você optou por continuar recebendo nossos e-mails. Ficamos felizes! :)
      </p>
    </>
  )
}

export default Maintain
