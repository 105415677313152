import { useEffect, useReducer, useState } from "react"

//Services
import { storage, url, http } from "services"

const reducer = (state, action) => {
  switch (action.type) {
    case "toUnsubscribe":
      return { layout: "toUnsubscribe" }
    case "unsubscribed":
      return { layout: "unsubscribed" }
    case "maintain":
      return { layout: "maintain" }
    case "timeout":
      return { layout: "timeout" }
    case "error":
      return { layout: "error" }
    default:
      throw new Error()
  }
}

export const useUnsubscribe = () => {
  const queryParams = url.getUrlParams()
  const layoutInitialState = { layout: "toUnsubscribe" }

  const [state, dispatch] = useReducer(reducer, layoutInitialState)
  const [layout, setLayout] = useState({
    logo: "",
    page_theme: {},
    path_url: "",
  })
  const [workspace, setWorkspace] = useState({
    customer_id: "",
    host: "",
    workspace_id: "",
  })

  const handleMaintain = () => {
    dispatch({ type: "maintain" })
  }

  const handleUnsubscribe = () => {
    http
      .requestUnsubscribe(workspace)
      .then(() => {
        storage.setUnsubscribeTimeout(workspace.host)
        dispatch({ type: "unsubscribed" })
        return
      })
      .catch((errors) => {
        console.error({ errors })
        dispatch({ type: "error" })
        return
      })
  }

  const handleWorkspace = () => {
    const workspaceId = queryParams.workspace_id

    http
      .getWorkspace({ workspace_id: workspaceId })
      .then((response) => {
        const { data } = response
        setLayout(data)
      })
      .catch((errors) => {
        console.error({ errors })
        dispatch({ type: "error" })
        return
      })
  }

  const renderWorkspace = () => {
    const workspaceHost = window.location.hostname

    const customerId = queryParams.customer_id
    if (customerId === null || customerId === "") {
      dispatch({ type: "error" })
      return
    }

    const workspaceId = queryParams.workspace_id
    if (workspaceId === null || workspaceId === "") {
      dispatch({ type: "error" })
      return
    }

    setWorkspace({
      customer_id: customerId,
      host: workspaceHost,
      workspace_id: workspaceId,
    })

    const canUnsubscribe = storage.checkUnsubscribeTimeout(workspace.host)
    if (!canUnsubscribe) {
      dispatch({ type: "timeout" })
      return
    }
  }

  useEffect(() => {
    handleWorkspace()
  }, [])

  useEffect(() => {
    renderWorkspace()
  }, [workspace.host, workspace.customer_id])

  return {
    handleMaintain,
    handleUnsubscribe,
    layout,
    state,
  }
}
