const getUrlParams = () => {
  const isClientSide = typeof window !== 'undefined'
  if (!isClientSide) return
  
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
}

export { getUrlParams }
