const KEY = "storage-unsubscribe"
const TWO_HOURS = 60 * 60 * 1000 * 2

const setUnsubscribeTimeout = (workspace) => {
  localStorage.setItem(`${KEY}-${workspace}`, Date.now())
}

const checkUnsubscribeTimeout = (workspace) => {
  const storageDate = localStorage.getItem(`${KEY}-${workspace}`)

  if (storageDate === null || storageDate === "") {
    return true
  }

  const checkIfTwoHoursPass = Date.now() - storageDate > TWO_HOURS

  if (checkIfTwoHoursPass) {
    return true
  }

  return false
}

export { setUnsubscribeTimeout, checkUnsubscribeTimeout }
