import React from "react"

const ToUnsubscribe = (props) => {
  return (
    <>
      <h1 className="title">
        tem certeza que deseja <br className="desktop--special" />
        <span>
          cancelar <br className="desktop" />
          sua inscrição?
        </span>
      </h1>
      <p className="description">
        <span>Nós vamos sentir a sua falta!</span> Você vai deixar de receber avisos sobre
        nossas ofertas,
        <br className="desktop" />
        mas continuaremos te informando sobre a sua conta.
      </p>
      <div className="action">
        <button className="action__button--secondary" onClick={props.maintain}>
          não, continuar recebendo
        </button>
        <button className="action__button--primary" onClick={props.unsubscribe}>
          sim, cancelar inscrição
        </button>
      </div>
    </>
  )
}

export default ToUnsubscribe
