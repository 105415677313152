import React from "react"

import IconExclamacao from "../../../static/icons/outlined/Exclamacao.svg"

const Unsubscribed = (props) => {
  return (
    <>
      <figure>
        <img src={IconExclamacao} alt="Atenção!" />
      </figure>
      <h1 className="title">ok, inscrição cancelada!</h1>
      <p className="description">
        Ficamos tristes com a sua partida, mas respeitamos sua escolha!
      </p>
    </>
  )
}

export default Unsubscribed
