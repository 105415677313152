import React from "react"

//Components
import ToUnsubscribe from "components/ToUnsubscribe"
import Unsubscribed from "components/Unsubscribed"
import Maintain from "components/Maintain"
import TimeOut from "components/TimeOut"
import Error from "components/Error"

//Hooks
import { useUnsubscribe } from "./hooks"

const Unsubscribe = () => {
  const { handleMaintain, handleUnsubscribe, layout, state } = useUnsubscribe()

  return (
    <div className="unsubscribe theme-default">
      <header className="unsubscribe__header">
        <div className="unsubscribe__container">
          {layout.logo ? (
            <figure dangerouslySetInnerHTML={{ __html: layout.logo }} />
          ) : null}
        </div>
      </header>
      <section className="unsubscribe__main">
        <div className="unsubscribe__container">
          <div className="unsubscribe__grid">
            <div className="unsubscribe__item--content">
              {state.layout === "toUnsubscribe" && (
                <ToUnsubscribe
                  maintain={handleMaintain}
                  unsubscribe={handleUnsubscribe}
                />
              )}
              {state.layout === "unsubscribed" && <Unsubscribed />}
              {state.layout === "maintain" && <Maintain />}
              {state.layout === "timeout" && <TimeOut />}
              {state.layout === "error" && <Error />}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Unsubscribe
